.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (min-width: 2560px) {
    html {
        font-size: min(max(16px, 4vw), 22px);
    }

}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

/*input {*/
/*  height: 48px!important;*/
/*  border-radius: 0.5em!important;*/
/*}*/

/*.ant-select-selector {*/
/*  height: 48px!important;*/
/*  border-radius: 0.5em!important;*/
/*  vertical-align: middle!important;*/
/*}*/

/*.ant-button {*/
/*  height: 48px!important;*/
/*  border-radius: 0.5em!important;*/
/*}*/

.App-link {
  color: #61dafb;
}

.dropzone{
  color: black;
  background: rgb(241,242, 252);
  min-height: 100px;
  min-width: auto;
  /*margin: 15px;*/
  border-color: gray;
  border-radius: 5px;
  border-style: dashed;
  text-align: center;
  margin-bottom: 15px;
}

div.upload-container ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: rgb(245, 247, 251);
}

div.upload-container ul li{
  padding: 20px 10px 10px 10px;
  /*border: dashed #316ddb;*/
  min-height: 40px;
  margin-bottom: 10px;
  background-color: white;
  border-radius: 5px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
